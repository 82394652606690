import { render, staticRenderFns } from "./PmOrganizationForm.vue?vue&type=template&id=6bf0ea80&scoped=true"
import script from "./PmOrganizationForm.vue?vue&type=script&lang=js"
export * from "./PmOrganizationForm.vue?vue&type=script&lang=js"
import style0 from "./PmOrganizationForm.vue?vue&type=style&index=0&id=6bf0ea80&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf0ea80",
  null
  
)

export default component.exports